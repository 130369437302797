import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import Post from "./Post";
import PhotoGallery from "./PhotoGallery";

class FeedList extends Component {
  constructor(props) {
    super(props);
    this.state = { current_page: 1, comments: [] };
  }

  render() {
    var items = [];
    this.props.posts.map((obj, idx) => {
      let _post = obj;
      let _asset = null;
      console.log("_post", _post);
      if (_post.media.length > 0) {
        _asset = <PhotoGallery post={_post} />;
      }
      if (_post.video) {
        if (_post.video.length > 0) {
          _asset = (
            <video controls>
              <source src={_post.video[0].url} />
            </video>
          );
        }
      }
      if (_post.user) {
        items.push(
          <Post
            key={idx}
            post={_post}
            user={this.props.user}
            asset={_asset}
          />
        );
      }
    });
    return items;
  }
}

export default FeedList;
