import React, { useEffect, useState } from "react";
import { BsHeartFill } from "react-icons/bs";
import ApiRequest from "../../libs/request.js";
import CurrentUser from "../../libs/CurrentUser.js";
import CustomModal from "../shared/CustomModal.js";

const PostLikeUsers = (props) => {

  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    if (props.postId) {
      const api = new ApiRequest("post", `/user/post-like`);
      api.send({
        post_id: props.postId,
      }, ({ data }) => {
        console.log("PostLikeUsers", data);
        setLoading(false);
        setLikes(data.post_likes);
      });
    }

  }
    , [props.postId]);

  return (
    <div>
      <h5 className="like-user-title">Likes</h5>
      {
        loading ? 
        <div className="like-loading-nolike">Loading Likes...</div> :
        <div>
        {likes.length > 0 ? likes.map((like) => (
          <div key={like.id} className="like-user-div">
            <img src={like.user.profile_photo} alt={like.user.full_name} />
            <span>{like.user.full_name}</span>
          </div>
        )) : 
        <div className="like-loading-nolike">No Likes</div>
        }
      </div>
      }
    </div>
  );


};

const LikeLink = (props) => {
  const [post, setPost] = useState(props.post);

  const onClick = (e) => {
    e.preventDefault();
    const postId = post.id;
    const likedByCurrentUser = post.liked; // Check if the post is already liked by the current user

    // Make an API request to toggle like/dislike
    const api = new ApiRequest("post", `/user/like-dislike`);
    api.send({ post_id: postId }, ({ data }) => {
      setPost((prevPost) => {
        const updatedPost = { ...prevPost };
        const updatedLikes = [...updatedPost.likes];
        const currentUserIndex = updatedLikes.findIndex((user) => user.id === CurrentUser.id());

        if (likedByCurrentUser) {
          // Remove the current user from likes array if already liked
          if (currentUserIndex !== -1) {
            updatedLikes.splice(currentUserIndex, 1);
            updatedPost.likes_count--; // Decrement likes count
          }
        } else {
          // Add the current user to likes array if not already liked
          if (currentUserIndex === -1) {
            updatedLikes.push({
              id: CurrentUser.id(),
              first_name: CurrentUser.first_name(),
              last_name: CurrentUser.last_name(),
              avatar: CurrentUser.avatar(),
            });
            updatedPost.likes_count++; // Increment likes count
          }
        }

        updatedPost.likes = updatedLikes;
        updatedPost.liked = !likedByCurrentUser; // Toggle liked status

        return updatedPost; // Return the updated post object
      });
    });
  };

  const cssClass = post.liked ? "_icon on" : "_icon";

  return (
    <div className="likes" style={{display: "flex"}}>
      <BsHeartFill onClick={onClick} className={cssClass} />
      {
        post.likes_count > 0 ?
        <CustomModal
        width={"30%"}
        trigger={<span style={{ marginLeft: "5px" }}>{post.likes_count} Likes</span>} content={
          <PostLikeUsers postId={post.id} />
        } />
          :
          <span style={{ marginLeft: "5px" }}>{post.likes_count} Likes</span>
      }

    </div>
  );
};

export default LikeLink;
