import { toast } from "react-toastify";

export function isset(obj) {
  return obj !== null && obj !== undefined && obj !== "";
}

export function formatDate(date) {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  }).format(date);
}

export function urlify(inputText) {
  if (!inputText) return inputText;

  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
}

export function params(variable) {
  var params = new URLSearchParams(location.search);
  return params.get(variable);
}
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function metaPostFormat(question, item) {
  if (question.indexOf("$") > -1 && !isNaN(item)) {
    return Number.parseFloat(item).toFixed(2);
  }

  if (item == "No" || item == "Yes") {
    return item;
  }

  if (question.indexOf("Contact Number") > -1) {
    return formatPhoneNumber(item);
  }

  if (!isNaN(item)) {
    if (question.indexOf("Radius") > -1) {
      return item + " miles";
    }
  }

  if (!isNaN(Date.parse(item))) {
    return formatDate(Date.parse(item));
  }
  return item;
}

export function phonenumber(val) {
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return val.match(phoneno);
}

export function time_ago(time) {
  switch (typeof time) {
    case "number":
      break;
    case "string":
      time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  var time_formats = [
    [60, "seconds", 1], // 60
    [120, "1 minute ago", "1 minute from now"], // 60*2
    [3600, "minutes", 60], // 60*60, 60
    [7200, "1 hour ago", "1 hour from now"], // 60*60*2
    [86400, "hours", 3600], // 60*60*24, 60*60
    [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
    [604800, "days", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
    [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
    [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
    [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = (+new Date() - time) / 1000,
    token = "ago",
    list_choice = 1;

  if (seconds == 0) {
    return "Just now";
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = "from now";
    list_choice = 2;
  }
  var i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == "string") return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
    }
  return time;
}

export function validFile(fi, type) {
  if (fi.files.length > 0) {
    for (let i = 0; i <= fi.files.length - 1; i++) {
      let fsize = fi.files.item(i).size;
      let file = Math.round(fsize / 1024);
      // The size of the file.
      if (file >= 51200) {
        toast.error("File too Big, please select a file less than 50MB")
        return false;
      }
      if (type == "photo") {
        // Allowing file type
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        var filePath = fi.value;
        if (!allowedExtensions.exec(filePath)) {
          toast.error("Invalid file type. Please select JPG, PNG or GIF")
          return false;
        }
      }
    }
  } else {
    return false;
  }
  return true;
}

export function time_convert(minutes) {
  var hours = Math.floor(minutes / 60);
  var minutes = minutes % 60;
  return hours + ":" + (minutes > 9 ? minutes : `0${minutes}`);
}

export function highLightTags(obj) {
  var _body = obj.post;
  try {
    if (
      obj.tag_friends &&
      obj.tag_friends.length > 0
    ) {
      obj.tag_friends.map((t) => {
        var _name = t["first_name"] + " " + t["last_name"];
        _body = _body.replace(
          _name,
          '<a title="View ' +
            _name +
            's profile" href="/user/profile/' +
            t["id"] +
            '#profile" className="tagged">' +
            _name +
            "</a>"
        );
      });
      return _body;
    }
  } catch (err) {
    return _body;
  }
  return _body;
}


export function isValidUrl(str) {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(str)) {
    return false;
  } else {
    return true;
  }
}

export function isValidEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function getPostTypes(type) {
  switch (type) {
    case "post":
      return "General Post";
    case "shared":
      return "Shared Post";
    case "crew":
      return "Crew Post";
    case "work": 
      return "Work Post";
    default:
      return "General Post";
  }
}