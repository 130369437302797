import axios from "axios";
import { toast } from "react-toastify";

class ApiRequest {
  constructor(method, url, noProgress = true) {
    this.method = method;
    this.url = url;
    this.status = null;
    if (noProgress) {
      // loadProgressBar();
    }
  }

  async get(fn) {
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API_ROOT + this.url,
      });
      fn(response);
    } catch (error) {
      this.status = error.response ? error.response.status : undefined;
      fn(error.response ? error.response.data : error, this.status);
      console.log(error);
    }
  }

  async getWithBody(params, fn) {
    let headers = { "Content-Type": "application/json" };
    if (localStorage.getItem("gorilla-token") != null) {
      headers["access-token"] = "Bearer " + localStorage.getItem("gorilla-token");
      headers["Authorization"] = "Bearer " + localStorage.getItem("gorilla-token");
    }
    try {
      const response = await axios({
        method: "get",
        url: process.env.REACT_APP_API_ROOT + this.url,
        data: params,
        headers: headers,
      });
      fn(response);
    } catch (error) {
      this.status = error.response ? error.response.status : undefined;
      fn(error.response ? error.response.data : error, this.status);
      console.log(error);
    }
  }

  async send(params, fn) {
    let headers = { "Content-Type": "application/json" };
    if (localStorage.getItem("gorilla-token") != null) {
      headers["access-token"] = "Bearer " + localStorage.getItem("gorilla-token");
      headers["Authorization"] = "Bearer " + localStorage.getItem("gorilla-token");
    }
    try {
      const response = await axios({
        method: this.method,
        url: process.env.REACT_APP_API_ROOT + this.url,
        data: params,
        headers: headers,
      });
      response.errors = false;
      fn(response);
      toast.success(response.data.message);
    } catch (error) {
      if (error.response && error.response.status) {
        this.status = error.response.status;
      }
      const responseData = error.response ? error.response.data : error;
      responseData.errors = true;
      fn(responseData, this.status);
      if (error.response && error.response.status === 422) {
        toast.error(responseData.message);
      }
      console.log(error);
    }
  }

  async send_file(params, fn) {
    let headers = { "Content-Type": "multipart/form-data" };
    if (localStorage.getItem("gorilla-token") != null) {
      headers["access-token"] = "Bearer " + localStorage.getItem("gorilla-token");
      headers["Authorization"] = "Bearer " + localStorage.getItem("gorilla-token");
    }
    try {
      const response = await axios({
        method: this.method,
        url: process.env.REACT_APP_API_ROOT + this.url,
        data: params,
        headers: headers,
      });
      fn(response);
    } catch (error) {
      fn(error.response ? error.response.data : error);
    }
  }
}

export default ApiRequest;
